import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import useRolesValidation from 'hooks/useRolesValidation';

import PartnersMenu from './Menus/PartnersMenu';
import DraftMenu from './Menus/DraftMenu';
import PlansMenu from './Menus/PlansMenu';
import TransactionsMenu from './Menus/TransactionsMenu';
import CommunicationsMenu from './Menus/CommunicationsMenu';
import BoardsMenu from './Menus/BoardsMenu';
import DocumentsMenu from './Menus/DocumentsMenu';
import SocietyDataMenu from './Menus/SocietyDataMenu';
import PortfoliosMenu from './Menus/PortfoliosMenu';
import CustomLandingMenu from './Menus/CustomLandingMenu';
import AdministrativesMenu from './Menus/AdministrativesMenu';

import './SideNav.scss';
import { getMenusVisibility } from './utils/getMenusVisibility';

function ExtendedMenu({ societyId, userId = null }) {
  const { hasAccessCaptable, isSocietyHolding, configuration } = useSelector(
    (state) => state?.society?.actualSociety
  ) || {
    hasAccessCaptable: false,
    isSocietyHolding: false,
    configuration: {},
  };

  const actualSociety = useSelector((state) => state?.society?.actualSociety);

  const societyPermissions = useSelector(
    (state) => state?.society?.permissions
  );
  const hasCustomLanding = useSelector(
    (state) => state?.society?.actualSociety?.configuration?.hasCustomLanding
  );

  const { role } = useSelector((state) => state?.society) || {};
  const { user } = useSelector((state) => state);

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const {
    isAdmin,
    isPartner,
    isBeneficiary,
    isInvestor,
    isRepresentant,
    isGuest,
    isHolder,
    isDirector,
  } = useSelector(
    (state) =>
      state?.society?.role || {
        isAdmin: false,
        isPartner: false,
        isBeneficiary: false,
        isInvestor: false,
        isRepresentant: false,
        isGuest: false,
        isHolder: false,
        isDirector: false,
      }
  );

  const rolesValidation = useRolesValidation(role);

  const {
    hasCustomLandingMenu,
    hasDashboardMenu,
    hasPartnersMenu,
    hasDraftsMenu,
    hasCommunicationsMenu,
    hasBoardMenu,
    hasDocumentsMenu,
    hasSocietyDataMenu,
    hasPlansMenu,
    hasTransactionsMenu,
    hasPortfolioMenu,
    hasAdministrativeMenu,
  } = getMenusVisibility({
    rolesValidation,
    societyPermissions,
    hasCustomLanding,
    isSocietyHolding,
    actualSociety,
  });

  const goToDashboard = () => {
    window.location.href = `#/sociedad/${societyId}`;
  };

  const getBoardLinkByRole = () => {
    if (isAdmin) {
      return `/juntas/${societyId}`;
    }

    if (isPartner) {
      return `/juntas/${societyId}/${userId}`;
    }

    if (isRepresentant) {
      return `/juntas/${societyId}/${userId}`;
    }

    return '';
  };

  return (
    <Accordion id="main-menu__extended" className="pl-4 p-md-0 bg-transparent">
      {hasCustomLandingMenu && <CustomLandingMenu societyId={societyId} />}

      {hasDashboardMenu && isAdmin && (
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          <li className="nk-menu-item has-sub" onClick={() => goToDashboard()}>
            <a className="nk-menu-link nk-menu-toggle">
              <span className="nk-menu-icon">
                <em className="icon ni ni-meter" />
              </span>
              <span className="nk-menu-text">Dashboard</span>
            </a>
          </li>
        </Accordion.Toggle>
      )}

      {hasPartnersMenu && (
        <PartnersMenu
          societyId={societyId}
          userId={userId}
          isAdmin={isAdmin}
          isPartner={isPartner}
          isInvestor={isInvestor}
          hasAccessCaptable={hasAccessCaptable}
        />
      )}

      {hasPortfolioMenu && <PortfoliosMenu societyId={societyId} />}

      {hasAdministrativeMenu && (isAdmin || isDirector) && (
        <AdministrativesMenu
          societyId={societyId}
          isDirector={isDirector}
          rolesValidation={rolesValidation}
        />
      )}

      {hasBoardMenu && (isAdmin || isPartner || isRepresentant) && (
        <BoardsMenu getBoardLinkByRole={getBoardLinkByRole} />
      )}

      {hasTransactionsMenu && isAdmin && (
        <TransactionsMenu societyId={societyId} />
      )}

      {hasPlansMenu && (isAdmin || isBeneficiary) && (
        <PlansMenu
          societyId={societyId}
          user={user}
          isBeneficiary={isBeneficiary}
          isAdmin={isAdmin}
        />
      )}

      {hasDraftsMenu && isAdmin && (
        <DraftMenu societyId={societyId} user={user} />
      )}

      {hasDocumentsMenu && isAdmin && <DocumentsMenu societyId={societyId} />}

      {hasSocietyDataMenu && isAdmin && (
        <SocietyDataMenu
          societyId={societyId}
          isDemo={isDemo}
          configuration={configuration}
        />
      )}

      {hasCommunicationsMenu && isAdmin && (
        <CommunicationsMenu societyId={societyId} />
      )}
    </Accordion>
  );
}

export default ExtendedMenu;
