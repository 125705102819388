import { store } from 'redux/store';

import getSignRedirection from 'modules/_shared/api/envelopeActions';
import { validateFactorialIntegrationFromUrl } from 'modules/society/api/validateFactorialIntegration';

interface RedirectUrlProps {
  prefix: string;
  redirectUrl: string;
}

interface QueryParams {
  [key: string]: string;
}

function extractQueryParams(queryString: string): QueryParams {
  const queryParams: QueryParams = {};
  if (queryString) {
    const paramPairs = queryString.split('&');

    paramPairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      const decodedKey = decodeURIComponent(key);
      const decodedValue = decodeURIComponent(value || '');
      queryParams[decodedKey] = decodedValue;
    });
  }

  return queryParams;
}

const getRedirectURL = async (): Promise<RedirectUrlProps> => {
  try {
    const prefix: string = '#';
    const emailRedirectUrl: string | null =
      localStorage.getItem('redirect_url');
    const signRedirectUrl: string | null =
      localStorage.getItem('sign_redirect');
    const oauthFactorialUrl: string | null =
      localStorage.getItem('factorial_oauth');

    let redirectUrl: string = '';

    if (emailRedirectUrl) {
      redirectUrl = emailRedirectUrl;
    } else if (signRedirectUrl) {
      const queryParams = extractQueryParams(signRedirectUrl);
      const { envelopeId, signerId } = queryParams;

      const result = await store.dispatch(
        getSignRedirection({
          envelopeId,
          signerId,
        })
      );

      if (result) {
        redirectUrl = result;
      }
    } else if (oauthFactorialUrl) {
      const baseIntegrationUrl = '/perfil-sociedad/integraciones';

      const result = await store.dispatch(
        validateFactorialIntegrationFromUrl({ code: oauthFactorialUrl })
      );

      const { societyId } = result;
      redirectUrl = `${baseIntegrationUrl}/${societyId}`;
    }

    return { prefix, redirectUrl };
  } catch (error) {
    return { prefix: '#', redirectUrl: '' };
  } finally {
    localStorage.removeItem('redirect_url');
    localStorage.removeItem('sign_redirect');
    localStorage.removeItem('factorial_oauth');
  }
};

export default getRedirectURL;
