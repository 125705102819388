import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { SocietyRepresentative } from 'types';

import NoItemsAvailable from 'modules/_shared/components/EmptyStates/NoItemsAvailable';
import SocietyRepresentativesTableRow from './SocietyRepresentativesTableRow';

type Props = {
  societyId: string;
  societyRepresentatives: SocietyRepresentative[];
  isReadOnly?: boolean;
};

const SocietyRepresentativesTableBody: FC<Props> = ({
  societyId,
  societyRepresentatives,
  isReadOnly,
}) => {
  const { t } = useTranslate();
  const hasData = societyRepresentatives?.length > 0;

  return (
    <>
      <tbody>
        {hasData ? (
          societyRepresentatives?.map(
            (representative: SocietyRepresentative, index: number) => (
              <SocietyRepresentativesTableRow
                index={index}
                societyId={societyId}
                representative={representative}
                isReadOnly={isReadOnly}
              />
            )
          )
        ) : (
          <NoItemsAvailable mainMessage={t('NoRepresentativesAvailable')} />
        )}
      </tbody>
    </>
  );
};

export default SocietyRepresentativesTableBody;
