import { format } from 'date-fns';

import { TranslateFn } from 'hooks/useTranslate';
import { dateTimeFormat } from 'constants/formats';
import { Employee, Society } from 'types';
import { getLastImportationDate } from 'utils/employees';

type EmployeesPageDescriptionProps = {
  society: Society;
  employees: Employee[];
  t: TranslateFn;
};

export const getEmployeesPageDescription = ({
  society,
  employees,
  t,
}: EmployeesPageDescriptionProps) => {
  <>
    {`${society?.name} ${t('HaveEmployees', {
      count: employees?.length,
    })}`}
    {` / `}
    {t('LastImportationDate')}:&nbsp;
    <b>
      {`${
        employees.length
          ? format(new Date(getLastImportationDate(employees)), dateTimeFormat)
          : 'N/A'
      }`}
    </b>
  </>;
};
