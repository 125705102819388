import directorTypes from './directorTypes';

const directorTypesOptions = (i18n) => {
  const currDirectorTypes = directorTypes(i18n);
  return [
    {
      name: i18n.t('UniqueAdmin'),
      value: currDirectorTypes.var.UNIQUE_ADMIN,
    },
    {
      name: i18n.t('JointAdmin'),
      value: currDirectorTypes.var.POOLED_ADMIN,
    },
    {
      name: i18n.t('Adviser'),
      value: currDirectorTypes.var.COUNSELOR,
    },
    {
      name: i18n.t('CEO'),
      value: currDirectorTypes.var.DELEGATE_COUNSELOR,
    },
    {
      name: i18n.t('BoardSecretary'),
      value: currDirectorTypes.var.COUNCIL_SECRETARY,
    },
    {
      name: i18n.t('IndependentDirector'),
      value: currDirectorTypes.var.INDEPENDENT_COUNSELOR,
    },
    {
      name: i18n.t('NonExecutiveCounselor'),
      value: currDirectorTypes.var.NON_EXECUTIVE_COUNSELOR,
    },
    {
      name: i18n.t('JointDelegateCounselor'),
      value: currDirectorTypes.var.JOINT_DELEGATE_COUNSELOR,
    },
    {
      name: i18n.t('ProprietaryCounselor'),
      value: currDirectorTypes.var.PROPRIETARY_COUNSELOR,
    },
    {
      name: i18n.t('PresidentCouncil'),
      value: currDirectorTypes.var.COUNCIL_PRESIDENT,
    },
    {
      name: i18n.t('NonDirectorMember'),
      value: currDirectorTypes.var.NON_COUNSELOR_VOWEL,
    },
    {
      name: i18n.t('SolidaryAdmin'),
      value: currDirectorTypes.var.SOLIDARY_ADMIN,
    },
    {
      name: i18n.t('SecretaryNoDirector'),
      value: currDirectorTypes.var.SECRETARY_NO_DIRECTOR,
    },
    {
      name: i18n.t('VicePresidentCouncil'),
      value: currDirectorTypes.var.COUNCIL_VICE_PRESIDENT,
    },
    {
      name: i18n.t('NonDirectorDeputySecretary'),
      value: currDirectorTypes.var.NON_DIRECTOR_DEPUTY_SECRETARY,
    },
    {
      name: i18n.t('Auditor'),
      value: currDirectorTypes.var.AUDITOR,
    },
    {
      name: i18n.t('Treasurer'),
      value: currDirectorTypes.var.TREASURER,
    },
  ];
};

export default directorTypesOptions;
