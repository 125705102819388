import React from 'react';

const styles = {
  title: {
    color: 'rgb(107,114,128)',
    height: '20px',
    lineHeight: '20px',
  },
  value: {
    fontSize: '30px',
  },
  valueExtra: {
    color: 'rgb(107,114,128)',
  },
  card: {
    borderRadius: '8px',
  },
  text: {
    fontFeatureSettings: 'normal',
    fontSize: '14px',
    fontVariationSettings: 'normal',
  },
  footer: {
    height: '24px',
  },
};

const CardKPI = ({
  title,
  value,
  valueExtra,
  comparisonValue,
  comparisonText,
  positiveDelta,
}) => {
  const deltaIcon = positiveDelta
    ? 'ni ni-arrow-up-right'
    : 'ni ni-arrow-down-right';

  const deltaColors = positiveDelta
    ? 'bg-teal-dim text-teal'
    : 'bg-danger-dim text-danger';

  const comparisonColor = positiveDelta ? 'text-teal' : 'text-danger';

  const showDelta = positiveDelta === true || positiveDelta === false;

  return (
    <div
      className="card card-bordered mt-0 p-4 flex-1 w-100"
      style={styles.card}
    >
      <p className="text-gray mb-1" style={styles.title}>
        {title}
      </p>
      <div className="flex w-full flex-row justify-start align-items-baseline">
        <h4 className="fw-medium mb-0" style={styles.value}>
          {value}
        </h4>
        <p className="text-gray ml-2" style={styles.text}>
          {valueExtra}
        </p>
      </div>
      <div className="flex w-full flex-row justify-start items-center space-x-2 mt-2">
        {showDelta && (
          <span
            className={`flex-shrink-0 inline-flex justify-center align-items-center cursor-default px-2 text-sm rounded-pill ${deltaColors}`}
            style={styles.footer}
          >
            <em className={`ni fs-12px fw-bold ${deltaIcon}`} />
          </span>
        )}

        {(comparisonValue || comparisonText) && (
          <div className="flex w-full flex-row justify-start items-center space-x-1 truncate ml-2">
            <p className={`${comparisonColor} mb-0`}>{comparisonValue || ''}</p>
            <p className="text-gray ml-1">{comparisonText || ''}</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default CardKPI;
