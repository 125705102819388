import i18n from 'i18n/config';
import { store } from 'redux/store';
import { setModal } from 'modules/_shared/redux/modalActions';

import { editUser } from 'utils/modals';
import EditPermissionsModal from 'modules/society/components/SocietyAdministrator/components/EditPermissionsModal';
import { deleteAdmin } from './deleteAdmin';

export const menuOptions = [
  {
    id: 'EDIT_ADMIN',
    icon: 'ni-edit',
    text: i18n.t('Edit'),
    action: editUser,
  },
  {
    id: 'EDIT_PERMISSIONS',
    icon: 'ni-edit',
    text: i18n.t('EditPermissions'),
    action: ({ data, societyId }) => {
      store.dispatch(
        setModal(
          <EditPermissionsModal administrator={data} societyId={societyId} />
        )
      );
    },
  },
  {
    id: 'DELETE_ADMIN',
    icon: 'ni-trash',
    text: i18n.t('Delete'),
    action: deleteAdmin,
  },
];
