import { FC, useEffect, useState } from 'react';

import { SocietyRepresentative } from 'types';

import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';
import DataTablePagination from 'modules/_shared/components/Tables/DataTablePagination';
import SocietyRepresentativesTableHeader from './SocietyRepresentativesTableHeader';
import SocietyRepresentativesTableBody from './SocietyRepresentativeTableBody';

type Props = {
  societyId: string;
  societyRepresentatives: SocietyRepresentative[];
};

const INITIAL_PAGE = 1;
const INITIAL_ROWS_PER_PAGE = 10;

const SocietyRepresentativesTable: FC<Props> = ({
  societyId,
  societyRepresentatives,
}) => {
  const { isReadOnly } = useGetSocietySectionPermissions().data;

  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState<number>(INITIAL_ROWS_PER_PAGE);
  const [pagination, setPagination] = useState({
    customItemPerPage: INITIAL_ROWS_PER_PAGE,
    itemPerPage: INITIAL_ROWS_PER_PAGE,
    currentPage: INITIAL_PAGE,
    totalItems: societyRepresentatives.length,
    totalPages: Math.ceil(
      societyRepresentatives.length / INITIAL_ROWS_PER_PAGE
    ),
  });

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (rowsCount: number) => {
    setRowsPerPage(rowsCount);
    setCurrentPage(INITIAL_PAGE);
  };

  const getVisibleRepresentatives = () => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return societyRepresentatives.slice(start, end);
  };

  useEffect(() => {
    setPagination({
      customItemPerPage: rowsPerPage,
      itemPerPage: rowsPerPage,
      currentPage,
      totalItems: societyRepresentatives.length,
      totalPages: Math.ceil(societyRepresentatives.length / rowsPerPage),
    });
  }, [currentPage, rowsPerPage, societyRepresentatives.length]);

  return (
    <div className="card card-bordered card-preview">
      <div className="table-responsive">
        <table className="nk-tb-list nk-tb-ulist">
          <SocietyRepresentativesTableHeader isReadOnly={isReadOnly} />

          <SocietyRepresentativesTableBody
            societyId={societyId}
            societyRepresentatives={getVisibleRepresentatives()}
            isReadOnly={isReadOnly}
          />
        </table>

        {societyRepresentatives?.length > 0 ? (
          <DataTablePagination
            itemPerPage={pagination.itemPerPage}
            totalItems={pagination.totalItems}
            currentPage={pagination.currentPage}
            customItemPerPage={pagination.customItemPerPage}
            paginate={handleChangePage}
            setRowsPerPage={setRowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            className="card-pagination-small border-top bg-lighter"
          />
        ) : null}
      </div>
    </div>
  );
};

export default SocietyRepresentativesTable;
