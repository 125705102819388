import { useTranslate } from 'hooks/useTranslate';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import toggleSideNav from 'modules/_shared/redux/sideNavActions';

import './SideNav.scss';

function SecondaryMenu({ dispatch }) {
  const { t } = useTranslate();

  const isUILimitedVersion = !!JSON.parse(
    process.env.REACT_APP_UI_LIMITED_VERSION || 'false'
  );

  return (
    <ul className="nk-menu nk-menu-sm" id="secondary-menu__fixed">
      <li className="nk-menu-heading">
        <span>{t('Account')}</span>
      </li>
      <li className="nk-menu-item">
        <Link
          to="/perfil-usuario/datos"
          className="nk-menu-link"
          onClick={() => dispatch(toggleSideNav())}
        >
          <span className="nk-menu-icon">
            <em className="icon ni ni-user-round" />
          </span>
          <span className="nk-menu-text">{t('MyData')}</span>
        </Link>
      </li>
      <li className="nk-menu-item">
        <Link to="/" className="nk-menu-link is-disable">
          <span className="nk-menu-icon">
            <em className="icon ni ni-report-profit" />
          </span>
          <span className="nk-menu-text">{t('Subscription')}</span>
        </Link>
      </li>

      {!isUILimitedVersion && (
        <li className="nk-menu-item">
          <Link
            to="/contacto"
            className="nk-menu-link"
            onClick={() => dispatch(toggleSideNav())}
          >
            <span className="nk-menu-icon">
              <em className="icon ni ni-external" />
            </span>
            <span className="nk-menu-text">{t('Contact')}</span>
          </Link>
        </li>
      )}

      <li className="nk-menu-item">
        <a
          href="https://ayuda.sttok.com/"
          target="_blank"
          rel="noreferrer"
          className="nk-menu-link"
          onClick={() => dispatch(toggleSideNav())}
        >
          <span className="nk-menu-icon">
            <em className="icon ni ni-info" />
          </span>
          <span className="nk-menu-text">{t('Help')}</span>
        </a>
      </li>
    </ul>
  );
}
function mapStateToProps({ showSideNav }) {
  return { showSideNav };
}

export default connect(mapStateToProps)(SecondaryMenu);
