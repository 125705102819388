import { Employee, Society } from 'types';
import {
  getEmployeeIncentive,
  getEmployeeSalaryAmount,
  getEmployeeTeam,
} from 'utils/employees';

type EmployeeDataProps = {
  employees: Employee[];
  society: Society;
  beneficiariesCIF: string[];
  t: any;
};

export const parseEmployeesData = ({
  employees,
  society,
  beneficiariesCIF,
  t,
}: EmployeeDataProps) =>
  employees?.map((employee) => ({
    employee,
    name: [employee.firstName, employee.lastName].join(' '),
    location: [
      employee.address?.city,
      employee.address?.country?.toUpperCase(),
    ].join(', '),
    status: {
      text: !employee.terminationDate ? t('Active') : t('Inactive'),
      color: !employee.terminationDate ? 'success' : 'danger',
    },
    isActive: !employee.terminationDate,
    currency: 'EUR',
    cif: employee.cif,
    cifType: employee.cifType,
    salary: getEmployeeSalaryAmount(employee),
    hasIncentive: {
      text: getEmployeeIncentive(employee, beneficiariesCIF)
        ? t('Yes')
        : t('Not'),
      color: getEmployeeIncentive(employee, beneficiariesCIF)
        ? 'success'
        : 'danger',
    },
    department: getEmployeeTeam(employee),
    societyId: society._id,
  }));
