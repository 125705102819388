import integrations from 'constants/integrations';
import { axiosV1 } from 'interceptors/axios';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { Dispatch } from 'redux';
import { decryptResponse } from 'utils/token';

type ValidateFactorialIntegrationProps = {
  societyId?: string;
  code: string;
};

type ValidationResponse = {
  status: number;
  message: string;
  societyId?: string;
};

export const validateFactorialIntegration = async ({
  societyId,
  code,
}: ValidateFactorialIntegrationProps) => {
  const factorialKey = integrations.factorial.key;
  const query = { societyId, integration: factorialKey, code };

  const { data } = await axiosV1.post('/integration/validate', query);

  const decodedData = await decryptResponse(data);

  return decodedData;
};

export const validateFactorialIntegrationFromUrl =
  ({ code }: ValidateFactorialIntegrationProps) =>
  async (dispatch: Dispatch) => {
    try {
      const factorialKey = integrations.factorial.key;
      const query = { integration: factorialKey, code };

      const { data } = await axiosV1.post('/integration/validate', query);

      const decodedData = (await decryptResponse(data)) as ValidationResponse;

      if (decodedData?.status === 200) {
        dispatch(addAlert(alertBodyTypes.FACTORIAL_INTEGRATION_SUCCESS));
      }

      return decodedData;
    } catch (error) {
      return dispatch(addAlert(alertBodyTypes.FACTORIAL_INTEGRATION_ERROR));
    }
  };
