export default {
  factorial: {
    key: 'factorial',
    name: 'Factorial HR',
    description: 'FactorialDescription',
    url: 'https://apidoc.factorialhr.com/docs',
    logo: 'https://files.readme.io/57cfbd5-small-Factorial_logo_radical_cut.png',
    hasApiKey: false,
  },
  personio: {
    key: 'personio',
    name: 'Personio',
    description: 'PersonioDescription',
    url: 'https://developer.personio.de/',
    logo: 'https://logosarchive.com/wp-content/uploads/2021/12/Personio-logo.svg',
  },
};
