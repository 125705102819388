import { Society } from 'types';
import { SocietyTypes } from 'types/Society';

type MenusVisibility = {
  rolesValidation: Record<string, boolean>;
  societyPermissions: any;
  hasCustomLanding: boolean;
  isSocietyHolding: boolean;
  actualSociety: Society;
};

const getMenusPermission = (permissions: any) => {
  const hasNotPermissionsSetted =
    !permissions?.customLanding &&
    !permissions?.dashboard &&
    !permissions?.partners &&
    !permissions?.portfolio &&
    !permissions?.drafts &&
    !permissions?.plans &&
    !permissions?.transactions &&
    !permissions?.communications &&
    !permissions?.boards &&
    !permissions?.documents &&
    !permissions?.societyConfiguration;

  return {
    hasCustomLandingPermission:
      hasNotPermissionsSetted || permissions?.customLanding?.enabled || false,
    hasDashboardPermission:
      hasNotPermissionsSetted || permissions?.dashboard?.enabled || false,
    hasPartnersPermission:
      hasNotPermissionsSetted || permissions?.partners?.enabled || false,
    hasPortfolioPermission:
      hasNotPermissionsSetted || permissions?.portfolio?.enabled || false,
    hasDraftsPermission:
      hasNotPermissionsSetted || permissions?.drafts?.enabled || false,
    hasPlansPermission:
      hasNotPermissionsSetted || permissions?.plans?.enabled || false,
    hasTransactionsPermission:
      hasNotPermissionsSetted || permissions?.transactions?.enabled || false,
    hasCommunicationsPermission:
      hasNotPermissionsSetted || permissions?.communications?.enabled || false,
    hasBoardPermission:
      hasNotPermissionsSetted || permissions?.boards?.enabled || false,
    hasDocumentsPermission:
      hasNotPermissionsSetted || permissions?.documents?.enabled || false,
    hasSocietyDataPermission:
      hasNotPermissionsSetted ||
      permissions?.societyConfiguration?.enabled ||
      false,
  };
};
export const getMenusVisibility = ({
  rolesValidation,
  societyPermissions,
  hasCustomLanding,
  isSocietyHolding,
  actualSociety,
}: MenusVisibility) => {
  const permissions = getMenusPermission(societyPermissions);

  const isAssocSociety = actualSociety?.societyType === SocietyTypes.ASSOC;

  const hasCustomLandingMenu =
    hasCustomLanding &&
    (rolesValidation.isAdmin ||
      rolesValidation.isPartner ||
      rolesValidation.isGuest);

  const hasDashboardMenu =
    permissions.hasDashboardPermission &&
    (rolesValidation.isAdmin || rolesValidation.isPartner);

  const hasPartnersMenu =
    permissions.hasPartnersPermission &&
    !rolesValidation.isBeneficiaryOnly &&
    !rolesValidation.isRepresentantOnly &&
    !rolesValidation.isGuestOnly;

  const hasDraftsMenu =
    permissions.hasDraftsPermission &&
    !rolesValidation.isBeneficiaryOnly &&
    !isAssocSociety;

  const hasCommunicationsMenu =
    permissions.hasCommunicationsPermission &&
    !rolesValidation.isBeneficiaryOnly;

  const hasBoardMenu =
    permissions.hasBoardPermission && !rolesValidation.isBeneficiaryOnly;

  const hasDocumentsMenu =
    permissions.hasDocumentsPermission && !rolesValidation.isBeneficiaryOnly;

  const hasSocietyDataMenu =
    permissions.hasSocietyDataPermission && !rolesValidation.isBeneficiaryOnly;

  const hasPlansMenu = permissions.hasPlansPermission && !isAssocSociety;

  const hasTransactionsMenu =
    permissions.hasTransactionsPermission && !isAssocSociety;

  const hasPortfolioMenu =
    (permissions.hasPortfolioPermission && rolesValidation.isHolder) ||
    (rolesValidation.isAdmin && isSocietyHolding);

  const hasAdministrativeMenu =
    permissions.hasSocietyDataPermission || rolesValidation.isDirectorOnly;

  return {
    hasCustomLandingMenu,
    hasDashboardMenu,
    hasPartnersMenu,
    hasDraftsMenu,
    hasCommunicationsMenu,
    hasBoardMenu,
    hasDocumentsMenu,
    hasSocietyDataMenu,
    hasPlansMenu,
    hasTransactionsMenu,
    hasPortfolioMenu,
    hasAdministrativeMenu,
  };
};
