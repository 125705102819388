import { SocietyRepresentative } from 'types';
import { TranslateFn } from 'hooks/useTranslate';
import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';
import { WidgetLegalTableData } from '../components/WidgetLegalTableExtended';
import { createRepresentativePoaDateText } from './createRepresentativePoaDateText';
import { formatRepresentativePoaLimits } from './formatRepresentativePoaLimits';

export const mapRepresentativesToLegalDashboard = (
  representatives: SocietyRepresentative[],
  societyId: string,
  translate: TranslateFn
): WidgetLegalTableData[] => {
  if (!representatives || representatives.length === 0) {
    return [];
  }

  return representatives.map((representative) => ({
    name: representative.socialDenomination,
    image: representative?.image || BLANK_PROFILE_PICTURE,
    value1: formatRepresentativePoaLimits(representative, translate),
    value2: createRepresentativePoaDateText(representative, translate),
    link: `/detalle-apoderado/${societyId}/${representative._id}`,
  }));
};
