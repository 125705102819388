/* eslint-disable react/jsx-props-no-spreading */
import { useContext, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  Route,
  HashRouter as Router,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Modal } from 'react-bootstrap';

import { cleanStoredData } from 'utils/cleanStoredData';
import { applyGlobalTheme } from 'utils/theme';
import { validateAndStoreRedirection } from 'utils/validateAndStoreRedirection';

import sizes from 'constants/sizes';
import { APP_THEME_FF } from 'constants/defaultConstants';

import { protectedRoutes } from 'routes/protectedRoutes';
import { unprotectedRoutes } from 'routes/unprotectedRoutes';

import Page404 from 'modules/_shared/pages/404';
import LegalAlert from 'modules/_shared/components/Alert';
import ThemeContext from 'modules/_shared/contexts/ThemeContext';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import { initAuthenticatedUser } from 'modules/profile/redux/userActions';
import { ScrollProvider } from 'modules/_shared/contexts/ScrollContext';

import { LegalRoute } from 'LegalRoute';

import ScrollToTop from './ScrollToTop';

import './App.scss';
import './assets/styles/Bootstrap.scss';
import './assets/styles/Components.scss';
import './assets/styles/Fonts.scss';
import './assets/styles/Icons.scss';
import './assets/styles/Layouts.scss';
import './assets/styles/Misc.scss';
import './assets/styles/Placeholder.scss';
import './assets/styles/Print.scss';
import './assets/styles/ReactSelect.scss';
import './assets/styles/Stepper.scss';
import './assets/styles/Styles.scss';
import './assets/styles/Timeline.scss';
import './assets/styles/Animation.scss';

const selectAppState = (state) => ({
  user: state.user,
  alerts: state.alerts,
  statemodal: state.modal,
  currentAlert: state.alerts[0],
  theme: state?.society?.actualSociety?.customization?.theme,
});

const emailRedirectRegex = /redirect=([^&]*)/;
const signedRedirectRegex = /signed=[^&]*&?(.*)/;
const oauthFactorialRegex = /oauth\/factorial\?code=([^&#]*)/;

function App() {
  const dispatch = useDispatch();

  const { setDarkTheme } = useContext(ThemeContext);
  const { user: auth0User, isAuthenticated, isLoading } = useAuth0();

  const { user, alerts, statemodal, currentAlert, theme } = useSelector(
    selectAppState,
    shallowEqual
  );

  const urlHash = useLocation().hash;
  const location = useLocation();
  const fullPath = location.pathname + location.search;

  const signedRedirectUrl = useLocation().search.match(signedRedirectRegex);
  const emailRedirectUrl = useLocation().search.match(emailRedirectRegex);
  const oauthFactorialUrl = fullPath.match(oauthFactorialRegex);

  const [modal, setModal] = useState(false);

  const loadSavedTheme = () => {
    const savedTheme = localStorage.getItem(APP_THEME_FF);

    if (savedTheme) {
      const isDarkTheme = savedTheme === 'dark';
      setDarkTheme(isDarkTheme);
    }
  };

  const protectedRouteElements = useMemo(
    () =>
      protectedRoutes.map((route) => (
        <LegalRoute
          key={route.id}
          {...route}
          user={user}
          isLoading={isAuthenticated || isLoading}
        />
      )),
    [user, isAuthenticated, isLoading]
  );

  const unprotectedRouteElements = useMemo(
    () => unprotectedRoutes.map((route) => <Route key={route.id} {...route} />),
    []
  );

  const renderModal = useMemo(() => {
    if (!modal) return null;

    return (
      <Modal
        size={modal?.props?.size || sizes.MD}
        dialogClassName={modal?.props?.fullScreen ? 'modal-90w' : ''}
        onHide={() => setModal(null)}
        backdrop="static"
        keyboard={false}
        centered
        show
      >
        {modal}
      </Modal>
    );
  }, [modal]);

  useEffect(() => {
    applyGlobalTheme(theme);
  }, [theme]);

  useEffect(() => {
    setModal(statemodal);
  }, [statemodal]);

  useEffect(() => {
    if (signedRedirectUrl && !emailRedirectUrl) {
      validateAndStoreRedirection(signedRedirectUrl, 'sign_redirect');
    } else if (emailRedirectUrl) {
      validateAndStoreRedirection(emailRedirectUrl, 'redirect_url', urlHash);
    }
  }, [signedRedirectUrl, emailRedirectUrl, urlHash]);

  useEffect(() => {
    if (oauthFactorialUrl?.[1]) {
      validateAndStoreRedirection(oauthFactorialUrl, 'factorial_oauth');
    }
  }, [oauthFactorialUrl]);

  useEffect(() => {
    if (isAuthenticated && auth0User && !user) {
      dispatch(initAuthenticatedUser(auth0User));
    }
  }, [auth0User, isAuthenticated, user, dispatch]);

  useEffect(() => {
    loadSavedTheme();
    cleanStoredData();
  }, []);

  return (
    <>
      {!!alerts.length && <LegalAlert {...currentAlert} />}

      <Router>
        <ScrollProvider>
          <ScrollToTop />
          <div className="nk-body npc-default has-aside no-touch nk-nio-theme">
            <div className="nk-app-root">
              {renderModal}

              <div className="nk-main ">
                <Switch>
                  {protectedRouteElements}
                  {unprotectedRouteElements}

                  <Route component={Page404} />
                </Switch>
              </div>
            </div>
          </div>
        </ScrollProvider>
      </Router>
      {isLoading && <CustomLoading />}
    </>
  );
}

export default App;
