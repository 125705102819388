import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

type Props = {
  isReadOnly: boolean;
};

const SocietyRepresentativesTableHeader: FC<Props> = ({
  isReadOnly,
}: Props) => {
  const { t } = useTranslate();

  return (
    <thead>
      <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
        <th className="nk-tb-col">
          <span>{t('NameAndSurnameSociety')}</span>
        </th>

        <th className="nk-tb-col tb-col-xl">
          <span>{t('StartDate')}</span>
        </th>

        <th className="nk-tb-col tb-col-xl">
          <span>{t('EndDate')}</span>
        </th>

        <th className="nk-tb-col">
          <span>{t('PositionColumn')}</span>
        </th>

        <th className="nk-tb-col">
          <span>{t('Status')}</span>
        </th>

        <th className="nk-tb-col">
          <span>{t('CommentsLabel')}</span>
        </th>

        <th className="nk-tb-col tb-col-xl text-center">
          <span>{t('Doc')}</span>
        </th>

        {!isReadOnly && (
          <th className="nk-tb-col">
            <span />
          </th>
        )}
      </tr>
    </thead>
  );
};

export default SocietyRepresentativesTableHeader;
