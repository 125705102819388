import { FC } from 'react';
import { State } from 'redux/initialState';
import { useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';
import { SocietyConfiguration, SocietyTypes } from 'types/Society';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import '../SideNav.scss';
import MenuItem from './MenuItem';

type Props = {
  societyId: string;
  isDemo: boolean;
  configuration: SocietyConfiguration;
};

const SocietyDataMenu: FC<Props> = ({ societyId, isDemo, configuration }) => {
  const { t } = useTranslate();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const isAssocSociety = actualSociety?.societyType === SocietyTypes.ASSOC;

  const hasSocietyValueLinkDisabled = isAssocSociety;
  const hasSharesValueLinkDisabled = isAssocSociety;
  const hasRealtiesLinkDisabled = isAssocSociety;

  return (
    <>
      <Accordion.Toggle as={Button} variant="link" eventKey="9">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-setting" />
            </span>
            <span className="nk-menu-text">{t('SocietyData')}</span>
          </a>
        </li>
      </Accordion.Toggle>

      <Accordion.Collapse eventKey="9">
        <ul className="nk-menu-sub">
          <MenuItem
            linkTo={`/perfil-sociedad/datos/${societyId}`}
            text="SocietyProfile"
          />

          {!hasSocietyValueLinkDisabled && (
            <MenuItem
              linkTo={`/perfil-sociedad/valor/${societyId}`}
              text="ValueSociety"
            />
          )}

          {!hasSharesValueLinkDisabled && (
            <MenuItem
              linkTo={`/perfil-sociedad/valor-participaciones/${societyId}`}
              text="ValueShares"
            />
          )}

          {!hasRealtiesLinkDisabled && (
            <MenuItem linkTo={`/inmuebles/${societyId}`} text="Realties" />
          )}

          {!isDemo && (
            <>
              <MenuItem
                linkTo={`/perfil-sociedad/administradores/${societyId}`}
                text="OtherUsers"
              />
              {configuration?.hasThirdPartyIntegrations && (
                <MenuItem
                  linkTo={`/perfil-sociedad/integraciones/${societyId}`}
                  text="Integrations"
                />
              )}
            </>
          )}

          <MenuItem
            linkTo={`/perfil-sociedad/configuracion/${societyId}`}
            text="Customization"
          />
        </ul>
      </Accordion.Collapse>
    </>
  );
};

export default SocietyDataMenu;
