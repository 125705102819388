/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import menuTypes from 'constants/menuTypes';
import { getTheme } from 'utils/theme';
import { societyEntities } from 'hooks/useGetActualSocietyProgressively';

import Page from 'modules/_shared/components/Pages/Page';
import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import PrintButton from 'modules/_shared/components/Buttons/PrintButton';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import LegalDashboard from 'modules/dashboard/pages/LegalDashboard';
import SummaryDashboard from 'modules/dashboard/components/SummaryDashboard';
import useGetSocietyDashboardData from 'modules/society/hooks/useGetSocietyDashboardData';
import { useGetMenu } from 'modules/administratives/hooks/useGetMenu';
import { getActualSocietyProgressive } from 'modules/society/utils/getActualSocietyProgressive';
import { useGetDashboardSectionPermissions } from 'modules/dashboard/hooks/useGetDashboardSectionPermissions';

import { SocietyTypes } from 'types/Society';
import SocietyProgressBar from './components/SocietyProgressBar';
import SocietyDashboardTypeSelect, {
  dashboardTypes,
} from './components/SocietyDashboardTypeSelect';

const societyEntitiesNeeded = [
  societyEntities.BASIC,
  societyEntities.SHARES,
  societyEntities.OPERATIONS,
  societyEntities.PARTNERS,
  societyEntities.BENEFICIARIES,
  societyEntities.INVESTORS,
  societyEntities.DRAFTS,
  societyEntities.STOCK_PLANS,
];

const SocietyDashboard = () => {
  const { t } = useTranslate();
  const { societyId } = useParams();
  const printRef = useRef();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const { isAdmin, isReadOnly } = useGetDashboardSectionPermissions().data;
  const { data, loadingState: isLoadingDashboard } =
    useGetSocietyDashboardData();

  const [selectedTheme, setSelectedTheme] = useState(getTheme());
  const [dashboardTypeSelected, setDashboardTypeSelected] = useState(null);

  const [isLoadingDashboardComplete, setIsLoadingDashboardComplete] =
    useState(false);
  const [isLoadingSocietyComplete, setIsLoadingSocietyComplete] =
    useState(false);

  const societyName = actualSociety?.name;

  const printFileName = `${societyName} - Sttok ${format(
    new Date(),
    'dd-MM-yyyy'
  )}`;

  const handleSwitchDashboardType = (e) => {
    setDashboardTypeSelected(e.target.value);
  };

  const handleProgressComplete = (societyData) => {
    getActualSocietyProgressive(user, societyData);
    setIsLoadingDashboardComplete(false);
    setIsLoadingSocietyComplete(true);
  };

  const getDashboardActions = () => {
    const PrintItem = (
      <PrintButton
        fileName={printFileName}
        printRef={printRef}
        icon="ni-printer-fill"
      />
    );

    const SelectItem = (
      <SocietyDashboardTypeSelect
        value={dashboardTypeSelected}
        onChange={handleSwitchDashboardType}
      />
    );

    const isPrintItemVisible = isAdmin;
    const isSelectItemVisible =
      isAdmin && actualSociety?.societyType !== SocietyTypes.ASSOC;

    return [
      isPrintItemVisible && PrintItem,
      isSelectItemVisible && SelectItem,
    ].filter(Boolean);
  };

  useGetMenu(menuTypes.EXTENDED);

  useEffect(() => {
    const dashboardDataIsLoaded =
      Object.values(isLoadingDashboard).every((value) => value === false) &&
      Object.values(data).every((value) => value !== null);

    if (dashboardDataIsLoaded) {
      setIsLoadingDashboardComplete(true);
    }
  }, [data, isLoadingDashboard]);

  useEffect(() => {
    if (!actualSociety) return;

    setDashboardTypeSelected(
      actualSociety?.societyType === SocietyTypes.ASSOC
        ? dashboardTypes.LEGAL
        : dashboardTypes.STANDARD
    );
  }, [actualSociety]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      const theme = getTheme();
      setSelectedTheme(theme);
    });
  }, []);

  return (
    <Page>
      {isLoadingSocietyComplete && (
        <PageListHeader
          subTitle={societyName || t('Society')}
          description={`${t('SocietyDashboardTitle')} ${societyName || ''}`}
          actions={getDashboardActions()}
        />
      )}

      {isLoadingDashboardComplete && (
        <SocietyProgressBar
          entities={societyEntitiesNeeded}
          labelStyle="mb-3"
          onProgressComplete={handleProgressComplete}
          showLabels
          societyId={societyId}
          wrapperStyle={`mb-4 ${isLoadingSocietyComplete ? 'fade-out' : ''}`}
        />
      )}

      {actualSociety && dashboardTypeSelected && (
        <div
          className="nk-block d-flex flex-wrap justify-content-between"
          id="margin-print-only"
          ref={printRef}
        >
          <PrintHeader title={t('PrintHeaderDashboardSociety')} />

          {dashboardTypeSelected === dashboardTypes.LEGAL && <LegalDashboard />}

          {dashboardTypeSelected === dashboardTypes.STANDARD && (
            <SummaryDashboard
              data={data}
              isLoading={isLoadingDashboard}
              isReadOnly={isReadOnly}
              societyId={societyId}
              societyName={societyName}
              selectedTheme={selectedTheme}
            />
          )}
        </div>
      )}
    </Page>
  );
};

export default SocietyDashboard;
