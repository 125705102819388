/* eslint-disable no-console */
import { SocietyCorporateId } from 'types/Society';

function trackEvent(mixpanel, name, properties) {
  if (!mixpanel?.config?.token) {
    console.error('[MIXPANEL_ERROR] => Token not found!');
    return;
  }

  const corporateId =
    process.env.REACT_APP_UI_CORPORATE_ID || SocietyCorporateId.DEFAULT;

  const updatedProperties = { ...properties, platform: corporateId };

  mixpanel.track(name, updatedProperties);
}

export default trackEvent;
