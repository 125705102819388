import actionTypes from 'redux/actions/actionTypes';
import employeesSource from 'constants/employeesSource';
import { decryptResponse } from 'utils/token';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { axiosV1 } from 'interceptors/axios';

/**
 * Get employees information from Factorial
 * @param {string} source - The source of employees data
 * @param {Array} currentEmployees - Current employees list
 * @param {Object} society - Society information containing the access token
 * @returns {Promise<{newEmployees: Array, updatedEmployees: Array}>} - New and updated employees
 */
export const getEmployeesInfo = async (source, currentEmployees, society) => {
  if (source !== employeesSource.FACTORIAL) {
    return { newEmployees: [], updatedEmployees: [] };
  }

  try {
    const query = { params: { societyId: society._id, source } };

    const { data } = await axiosV1.get(`/employees`, query);

    const employeesWithContractsAndTeams = (await decryptResponse(data)) || [];

    const currentEmployeesIds = currentEmployees.map(
      (employee) => employee.externalId
    );

    const { newEmployees, updatedEmployees } =
      employeesWithContractsAndTeams?.reduce(
        (result, employee) => {
          if (!currentEmployeesIds.includes(employee.externalId)) {
            result.newEmployees.push(employee);
          } else {
            result.updatedEmployees.push(employee);
          }
          return result;
        },
        { newEmployees: [], updatedEmployees: [] }
      );

    return { newEmployees, updatedEmployees };
  } catch (error) {
    throw new Error(`ERROR_GETTING_EMPLOYEES: ${error.message}`);
  }
};

export const getSocietyEmployees =
  (societyId, source = '') =>
  async (dispatch) => {
    try {
      const query = { params: { societyId, source } };

      const { data } = await axiosV1.get(`/employees`, query);

      const decodedData = await decryptResponse(data);

      if (source !== employeesSource.FACTORIAL) {
        dispatch({
          type: actionTypes.GET_EMPLOYEES,
          data: decodedData,
        });
      }
    } catch (error) {
      return dispatch(addAlert(alertBodyTypes.ERROR_IMPORTING_EMPLOYEES));
    }
  };

export const getEmployeeById = (employeeId) => async (dispatch) => {
  try {
    const { data } = await axiosV1.get(`/employees/${employeeId}`);

    const decodedData = await decryptResponse(data);

    return decodedData;
  } catch (error) {
    dispatch(addAlert(alertBodyTypes.ERROR_GETTING_EMPLOYEE));
  }
};

export const saveEmployees =
  (employees, source, societyId) => async (dispatch) => {
    const newEmployeesForUpload = employees.newEmployees?.map((employee) => ({
      ...employee,
      source,
      society: societyId,
      importationDate: Date.now(),
    }));

    const oldEmployeesForUpdate = employees.updatedEmployees?.map(
      (employee) => ({
        ...employee,
        source,
        society: societyId,
        importationDate: Date.now(),
      })
    );

    const body = {
      societyId,
      newEmployees: newEmployeesForUpload,
      existentEmployees: oldEmployeesForUpdate,
    };

    try {
      const { data } = await axiosV1.post(`/employees`, body);

      const decodedData = await decryptResponse(data);

      dispatch({
        type: actionTypes.GET_EMPLOYEES,
        data: decodedData,
      });

      dispatch(addAlert(alertBodyTypes.EMPLOYEES_IMPORTED_SUCCESSFULLY));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_IMPORTING_EMPLOYEES));
    }
  };
