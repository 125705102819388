import { State } from 'redux/initialState';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { SocietyTypes } from 'types/Society';
import { LegalDashboardData } from '../types';

type VisibleCards = {
  IDENTIFICATION: boolean;
  ADDRESSES: boolean;
  INCORPORATION: boolean;
  FISCAL_YEAR: boolean;
  DIRECTORS: boolean;
  COMMITTEES: boolean;
  CAPITAL: boolean;
  PARTNERS: boolean;
  PARTNERS_CHART: boolean;
  AUDITORS: boolean;
  REPRESENTATIVES: boolean;
  RESERVED_MATTERS: boolean;
};

const initialState: VisibleCards = {
  IDENTIFICATION: false,
  ADDRESSES: false,
  INCORPORATION: false,
  FISCAL_YEAR: false,
  DIRECTORS: false,
  COMMITTEES: false,
  CAPITAL: false,
  PARTNERS: false,
  PARTNERS_CHART: false,
  AUDITORS: false,
  REPRESENTATIVES: false,
  RESERVED_MATTERS: false,
};

export const useLegalDashboardVisibleCards = (
  data: LegalDashboardData
): VisibleCards => {
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const visibleCards = useMemo(() => {
    if (!data || !actualSociety) {
      return initialState;
    }

    const isAssocSociety = actualSociety?.societyType === SocietyTypes.ASSOC;

    return {
      IDENTIFICATION: !!data.summary?.identificationNumbers,
      ADDRESSES: !!data.summary?.addresses,
      INCORPORATION: !!data.summary?.incorporation,
      FISCAL_YEAR: !!data.summary?.fiscalYear,
      DIRECTORS: data.directors.length > 0,
      COMMITTEES: data.committees?.length > 0,
      CAPITAL: !!(data.capital && !isAssocSociety),
      PARTNERS: !!(data.partners && !isAssocSociety),
      PARTNERS_CHART: !!(data.partnersChart && !isAssocSociety),
      AUDITORS: data.auditors.length > 0,
      REPRESENTATIVES: data.representatives.length > 0,
      RESERVED_MATTERS: !!data.summary?.reservedMatters,
    };
  }, [data, actualSociety]);

  return visibleCards;
};
